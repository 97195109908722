<template>
  <div
    id="AuthLogin"
    class="page-login d-flex align-items-center justify-content-center"
  >
    <div class="container">
      <div class="main-card">
        <div class="row" style="height: 700px">
          <div class="col-6 img-login">
            <img src="/images/DD0fKMPg8ZA.jpg" class="img-bg" />

            <div class="iconify">
              <iconify
                icon="ant-design:caret-left-outlined"
                class="font-white"
              />
            </div>
          </div>
          <div class="col-6 input-login px-5 pt-5 pb-4">
            <div class="d-flex flex-column h-100">
              <div class="text-center">
                <img src="/images/logo.png" />
              </div>
              <div
                class="text-center fontsize-h3 font-headblue weight-700 my-5"
              >
                ระบบประเมินคุณภาพประปาหมู่บ้าน
              </div>

              <div class="flex-fill mt-3">
                <b-form @submit="handleLogin">
                  <div
                    class="mb-3 position-relative"
                    :class="{
                      'login-input-focus': isUserCardFocus,
                      'login-input-req': submit && $v.usercard.$invalid,
                    }"
                  >
                    <label for="user" class="label-top form-label weight-700">
                      บัญชีผู้ใช้
                    </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                          <img src="/images/user.png" />
                        </span>
                      </div>
                      <input
                        type="text"
                        maxlength="13"
                        class="form-control"
                        v-model="usercard"
                        aria-describedby="basic-addon1"
                        placeholder="กรอกเลขบัตรประชาชน"
                        @focus="isUserCardFocus = true"
                        @blur="isUserCardFocus = false"
                      />
                    </div>
                  </div>
                  <div
                    class="text--error"
                    v-show="$v.usercard.$invalid && submit"
                  >
                    เลขบัตรประชาชนไม่ถูกต้อง
                  </div>
                  <!-- <div
                    class="text--error"
                    v-show="!$v.usercard.validator && submit"
                  >
                    เลขบัตรประชาชนไม่ถูกต้อง
                  </div> -->
                  <div class="mt-5">
                    <button type="submit" class="btn btn-submit w-100">
                      เริ่มทำแบบประเมิน
                    </button>
                  </div>
                </b-form>
                <div class="mt-5">
                  <button
                    class="
                      btn btn-bluesystem
                      w-100
                      d-flex
                      align-items-center
                      justify-content-center
                    "
                    @click="goToReport"
                  >
                    <iconify
                      icon="ant-design:pie-chart-filled"
                      class="mr-2"
                    ></iconify>
                    <div>รายงานผลการประเมิน (Dashboard)</div>
                  </button>
                </div>
              </div>
              <div class="text-right mt-4">
                <router-link :to="{ name: 'Admin' }"
                  >สำหรับเจ้าหน้าที่</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "auth-login",
  data() {
    return {
      usercard: null,
      isUserCardFocus: false,
      submit: false,
    };
  },
  validations: {
    usercard: {
      required,
      minLength: minLength(13),
      maxLength: maxLength(13),
      validator(rule, value, callback) {
        const id = value.usercard;

        let sum = 0;
        if (!this.isNumeric(id)) return false;
        if (id.substring(0, 1) == 0) return false;
        if (id.length != 13) return false;
        for (let i = 0; i < 12; i++) sum += parseFloat(id.charAt(i)) * (13 - i);
        if ((11 - (sum % 11)) % 10 != parseFloat(id.charAt(12))) return false;
        return true;
      },
    },
  },
  created() {
    window._login = this;
  },
  methods: {
    goToReport() {
      let routeData = this.$router.resolve({
        name: "Report",
      });
      window.open(routeData.href);
    },
    isNumeric(input) {
      let RE =
        /^-?(0|INF|(0[1-7][0-7]*)|(0x[0-9a-fA-F]+)|((0|[1-9][0-9]*|(?=[\.,]))([\.,][0-9]+)?([eE]-?\d+)?))$/;
      return RE.test(input);
    },
    handleLogin(event) {
      event.preventDefault();
      this.submit = true;

      if (this.$v.usercard.$invalid) {
        return;
      }
      const params = {
        username: process.env.VUE_APP_REPORTER_USER,
        password: process.env.VUE_APP_REPORTER_PASSWORD,
      };

      this.$store
        .dispatch("account/login", params)
        .then(async (res) => {
          if (res.status) {
            this.$store.commit("account/saveLocalToken");
          } else {
          }
        })
        .then(async (res) => {
          this.$store
            .dispatch("usercard/login", this.usercard)
            .then(
              (res) => {
                if (res) {
                  this.$store.commit("usercard/saveLocalUserCard");
                  this.$router.push({ name: "AssessmentList" });
                }
              },
              (error) => {}
            )
            .catch((error) => {});
        })
        .then(async (res) => {
          // getProfile
          await UserService.getProfile()
            .then((res) => {
              this.$store.commit("account/setProfile", res);
            })
            .catch((error) => {
              this.$store.commit("account/setProfile", {});
            });
        })
        .catch((error) => {})
        .finally((f) => {
          return true;
        });
    },
  },
};
</script>

<style lang="scss"></style>
